$brand-color: #7b2927;
.brand-container {
	border:2px solid $brand-color;
	display:flex;
	flex-flow:row wrap;
	background-color:#FFF;
	margin-bottom:6rem;
	&__heading {
		width:100%;
		flex:1 1 auto;
		padding:3.5rem;
		.img-logo {
			max-width:500px;
			width:100%;
		}
		border:2px solid $brand-color;
	}
	&__description {
		width:100%;
		flex:1 1 auto;
		padding:3.5rem;
		border:2px solid $brand-color;
		.p1 {
			font-family:"pill-gothic-300mg",Arial,"Helvetica Neue",Helvetica,sans-serif;
			color:$brand-color;
			font-size:2.2rem;
			line-height:1.5;
		}
		.p2 {
			color:$brand-color;
			font-size:1.6rem;
			line-height:1.5;
		}
	}
	&__feature {
		width:50%;
		flex:1 1 auto;
		@media screen and (max-width: 1024px) {
	        width:100%;
		}
		padding:3.5rem;
		border:2px solid $brand-color;

		.feature-heading {
			text-transform: uppercase;
			font-size:2.2rem;
			font-family:"pill-gothic-300mg",Arial,"Helvetica Neue",Helvetica,sans-serif;
			color:$brand-color;
			font-weight:bold;
			line-height:1.5;
			margin-bottom:2.5rem;
			.img-tick {
				margin-right:1rem;
			}
		}
		.feature-content {
			font-size:1.6rem;
			color:$brand-color;
			line-height:1.5;
		}
	}
	&__download {
		width:100%;
		flex:1 1 auto;
		background-color:$brand-color;
		a {
			font-family:"pill-gothic-300mg",Arial,"Helvetica Neue",Helvetica,sans-serif;
			font-size:1.6rem;
			padding:1.5rem 3.5rem;
			display:block;
			color:#FFF;
			text-transform: uppercase;
		}
	}
}
#our-brands {
	.bg {
		background-image:url(/images/brands/bg-brands.jpg);
		background-size:cover;
		background-repeat:no-repeat;
		background-position:50% 100%;
	}
	footer {
		background-color:$brand-color;
	}
}
